/* Global Body Styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    color: white;
  }
  
  /* Main Page Container with Background Image */
  .success-page {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;
    background: url("https://cdn.builder.io/api/v1/image/assets/TEMP/c01c7c33b66b11f5fa0f662991f8326f9a066ae3465a06053c2cff2cb297fbd4") no-repeat center center fixed;
    background-size: cover;
  }
  
  /* Dark overlay on the page */
  .success-page::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1;
  }
  
  /* Content Container */
  .content-container {
    position: relative;
    z-index: 2;
    background-color: rgba(33, 33, 33, 0.9);
    border-radius: 40px;
    padding: 50px 0 146px;
    width: 100%;
    max-width: 1304px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  /* Page Title */
  .page-title {
    color: white;
    letter-spacing: 1.8px;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 40px;
  }
  
  /* Accounts Container */
  .accounts-container {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /* Account Card Styles */
  .account-card {
    background-color: #212121;
    border-radius: 20px;
    padding: 40px;
    width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .account-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
  
  /* Specific Card Borders */
  .account-card.steam {
    border: 1px solid #efba4d;
  }
  
  .account-card.discord {
    border: 1px solid #5865F2;
  }
  
  /* Avatar */
  .account-avatar {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  /* Account Info */
  .account-info {
    font-size: 22px;
    margin-bottom: 20px;
  }
  
  .account-name {
    font-weight: 400;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 5px;
  }
  
  .account-id {
    font-weight: 400;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.9);
  }
  
  /* Action Buttons Container */
  .action-buttons {
    display: flex;
    gap: 20px;
    margin-top: 40px;
  }
  
  /* Button Styling */
  .link-button {
    border: none;
    border-radius: 10px;
    padding: 18px 48px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    background-color: #efba4d;
    color: white;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .link-button:hover:not(:disabled) {
    background-color: #d0a039;
    transform: scale(1.03);
  }
  
  /* Button style for connected (steam) */
  .link-button.connected {
    background-color: #00C11F;
    cursor: default;
  }
  
  /* Button style for joining Discord Server */
  .join-discord-button {
    background-color: #5865F2;
  }
  
  .join-discord-button:hover:not(:disabled) {
    background-color: #4e59d3;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 991px) {
    .success-page {
      padding: 20px;
    }
    .content-container {
      padding: 50px 20px 100px;
    }
    .accounts-container {
      flex-direction: column;
      align-items: center;
    }
    .account-card {
      width: 100%;
      max-width: 380px;
    }
    .action-buttons {
      flex-direction: column;
      gap: 20px;
    }
  }
  